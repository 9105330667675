import localFont from "@next/font/local";
import "../styles/index.scss";

const AmericanaStdBold = localFont({ src: "../styles/Americana-Bold.woff2" });

function MyApp({ Component, pageProps }) {
  return (
    <main className={AmericanaStdBold.className}>
      <Component {...pageProps} />
    </main>
  );
}

export default MyApp;
